<template>

    <v-row
        class="fade-in"
        justify="center"
        :class="{ 'shift-down': $vuetify.breakpoint.mdAndUp }"

    >
    <v-col  cols="12" md="8">
       <h1 class="text-center">{{ $t('iconGallery') }}
        </h1>

        <v-card
            class="pa-12 mt-8"
            outlined
        >
            <v-select
                style="max-width: 200px"
                class="d-flex mx-auto"
                :label="$t('pickIcon')"
                hide-details
                v-model="icon"
                :items="icons"
            >
                <template v-slot:item="data">
                    <v-row align="center">
                        <v-col style="max-width:40px" class="mr-1">
                            <img
                                :src="pathPrefix + data.item + '/5.png'"
                                width="40"
                                style="height: auto"
                            />
                        </v-col>
                        <v-col class="text-body2">{{ data.item }}</v-col>
                    </v-row>
                </template>
            </v-select>

            <img
                :src="imgPath"
                width="300"
                style="height: auto"
                class="d-flex mx-auto"
            />

            <v-slider
                style="max-width: 500px"
                :tick-labels="[1, 2, 3, 4, 5, 6]"
                track-color="grey"
                :thumb-color="color"
                name="rating"
                v-model="rating"
                :color="color"
                step="1"
                min="1"
                max="6"
                ticks="always"
                tick-size="3"
                thumb-label
                always-dirty
                class="mb-2 mx-auto"
            ></v-slider>
        </v-card>
    </v-col>
    </v-row>




</template>

<script>
export default {
    props: [""],
    components: {},
    data: () => ({
        rating: 5,
        icon: "sun",
        icons: [
            "balance",
            "book",
            "bulb",
            "cloud",
            "coffee",
            "crab",
            "cup",
            "donut",
            "fridge",
            "hammer",
            "hat",
            "ice",
            "ice_cream",
            "kitty",
            "ladder",
            "laptop",
            "mobile",
            "mouse",
            "mouth",
            "pen",
            "plant",
            "plant2",
            "screwdriver",
            "sun",
            "superman",
            "target",
            "target2",
            "toast",
            "toast2",
            "tree",
            "tree2",
            "tv"
        ],
        pathPrefix: "https://tbpics.joineer.com/surveyicons/"
    }),
    methods: {},
    computed: {
        imgPath() {
            return this.pathPrefix + this.icon + "/" + this.rating + ".png";
        },
        color() {
            if (this.rating > 4) return "green";
            if (this.rating > 3) return "orange";
            return "red";
        }
    }
};
</script>
