
function getToStringWithWarning(error) {
    console.log('%cgetToStringWithWarning() is used for: ', 'font-weight: bolder; color: darkorange', error);
    return error?.toString().replace('[object Object]', '').trim();
}

export const parseError = function (error) {
    return error?.response?.data?.code
        || error?.response?.data?.message
        || error?.response?.data.detail
        || error?.message
        || error?.response?.title
        || error?.response?.type
        || getToStringWithWarning(error)
        || 'genericApiErrorMessage';
};
