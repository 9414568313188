<template>
    <div>
        <v-btn
            @click="dialog = true"
            id="helpButton"
            :ripple="false"
            text
            color="grey"
        ><span v-if="$vuetify.breakpoint.smAndUp" >{{ $t('howDoesThisWork') }} </span> <v-icon class="ml-1">mdi-help-circle-outline</v-icon>
        </v-btn>
        <v-dialog v-model="dialog" max-width="950">
            <v-card class="pa-3">
                <div class="ma-3 d-flex">
                    <div class="text-h5">{{ $t("hierarchyTitle") }}</div>
                    <v-spacer></v-spacer>
                    <v-btn @click="dialog = false" icon
                        ><v-icon>mdi-close</v-icon></v-btn
                    >
                </div>

                <v-card-text>
                    <v-img
                        :src="require('@/assets/images/flat.jpg')"
                        class="ma-1"
                        contain
                    ></v-img>
                    <v-divider class="my-6" />
                    <v-img
                        :src="require('@/assets/images/level1.jpg')"
                        class="ma-1"
                        contain
                    ></v-img>
                    <v-divider class="my-6" />
                    <v-img
                        :src="require('@/assets/images/level2.jpg')"
                        class="ma-1"
                        contain
                    ></v-img>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    props: [""],
    mounted(){
        //allow linking users to see the dialog open by default with https://tools.joineer.com/#/validate?showHelpDialog=true
        if(this.$route.query.showHelpDialog){
            this.dialog = true;
        }

    },
    components: {},
    data: () => ({
        dialog: false
    }),
    methods: {},
    computed: {}
};
</script>

<style>
#helpButton {
    position: absolute;
    right: 2px;
    top: 2px;
}
</style>
