import Configuration from "@/services/configuration";
import router from "../router";
import VueGtm from 'vue-gtm';
import Vue from "vue";

class AnalyticsService {

    #enabled = false;

    init() {
        this.#enabled = Configuration.booleanValue('analyticsEnabled');
        if (this.#enabled) {
            Vue.use(VueGtm, {
                id: 'GTM-K67M4L7',
                enabled: this.#enabled,
                debug: false, // Whether or not display console logs debugs (optional)
                vueRouter: router, // Pass the router instance to automatically sync with router (optional
            });
        }
    }

}

export default new AnalyticsService();
