<template>
    <v-app id="app">
        <v-main>
            <v-container>
                <NavBar />
                <router-view class="fade-in" />
            </v-container>
        </v-main>
        <v-footer padless color="transparent">
            <span class="text--disabled float-left ml-4">{{ VERSION }}</span>
            <v-container>
                <v-row justify="center">
                    <v-col>
                        <a href="https://joineer.com" target="_blank">
                            <v-img
                                src="https://tbpics.joineer.com/companylogos/email/joineer_logo.gif"
                                height="20"
                                contain
                            ></v-img
                        ></a>
                    </v-col>
                </v-row>
            </v-container>
        </v-footer>
    </v-app>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import { VERSION } from "@/utils/globalconsts.js";
export default {
    name: "App",
    components: { NavBar },
    data: ()=>({
        VERSION: VERSION,
    }),
};
</script>

<style>

</style>
