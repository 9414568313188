<template>
    <v-row
        justify="center"
        :class="{ 'shift-down': $vuetify.breakpoint.mdAndUp }"
        class="fade-in"
    >
        <v-col cols="12" md="8">
            <h1 :class="{ 'text-h5': $vuetify.breakpoint.smAndDown }">
                {{ $t("validatorTitle") }}
            </h1>

            <v-img
                max-width="200px"
                :src="require('@/assets/images/importer.png')"
                class="mx-auto d-flex"
                contain
            ></v-img>

            <v-card class="pa-12 mt-5" outlined>
                <HelpDialog />
                <p class="">{{ $t("intro") }}</p>

                <p class="grey--text">
                    <strong>
                        <a
                            :title="$t('download')"
                            href="https://tbpics.joineer.com/documents/excel_import_template_v6.3.xlsx"
                        >
                            {{ $t("companyTemplate") }} V6.3
                            <v-icon color="#1379cc" small
                                >mdi-download</v-icon
                            ></a
                        ></strong
                    >
                </p>

                <v-file-input
                    ref="fileInputRef"
                    :label="$t('companyFile')"
                    @change="fileChange"
                    v-model="file"
                    accept=".xlsx, .xls"
                    style="max-width: 300px"
                ></v-file-input>

                <v-btn
                    color="primary"
                    :loading="loading"
                    @click="upload"
                    :disabled="!file"
                    class="mt-4"
                    large
                    >{{ $t("validate") }}</v-btn
                >

                <div class="successAlert mt-5" v-if="successMessage">
                    <div class="d-flex align-center">
                        <v-icon color="success" large class="mr-2"
                            >mdi-check-circle</v-icon
                        >
                        <div>
                            <span v-if="!warning">
                                {{ $t("validateSuccess") }}
                            </span>
                            <span v-else>
                                {{ $t("validateSuccessTitle") }}
                                <strong> {{ $t("withWarnings") }}</strong></span
                            >
                        </div>
                    </div>

                    <div v-if="warnings && warnings.length > 0" class="mt-2">
                        <div
                            class="message-inner"
                            v-for="item in warnings"
                            :key="item"
                        >
                            <v-icon color="yellow darken-1" left
                                >mdi-alert-circle</v-icon
                            >
                            <div>{{ item }}</div>
                        </div>
                    </div>
                </div>

                <!-- ERRORS -->

                <div class="errorAlert mt-5" v-if="showErrorAlert">
                    <div class="d-flex align-center">
                        <v-icon color="error" large class="mr-2"
                            >mdi-alert</v-icon
                        >

                        <i18n path="validateFail">
                            <span v-if="fileName"
                                >{{ $t("of") }} '{{ fileName }}'</span
                            >
                        </i18n>
                    </div>

                    <div class="mt-2">
                        <div v-if="errors.length > 0">
                            <div
                                v-for="(item, i) in errors"
                                :key="`${item.message}-${i}`"
                                class="message-inner"
                            >
                                <v-icon color="error" left
                                    >mdi-alert-circle</v-icon
                                >
                                <div class="d-flex flex-column">
                                    <b>{{ item.message }}</b>
                                    <div v-if="item.rowIndex">
                                        <v-icon small color="grey"
                                            >mdi-table</v-icon
                                        >
                                        {{
                                            convertToExcelAddress(
                                                item.sheetName,
                                                item.columnIndex + 1,
                                                item.rowIndex + 1
                                            )
                                        }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-else class="message-inner">
                            <v-icon color="error" left>mdi-alert-circle</v-icon
                            >{{ error }}
                        </div>

                        <div v-if="warnings && warnings.length > 0">
                            <div
                                v-for="item in warnings"
                                :key="item"
                                class="message-inner"
                            >
                                <v-icon color="yellow darken-1" left
                                    >mdi-alert-circle</v-icon
                                >
                                <div>
                                    {{ item }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
import { validateFile } from "../api/validate.api";
import HelpDialog from "@/components/HelpDialog.vue";
import { parseError } from "@/utils/parsers";

export default {
    name: "Validator",
    components: { HelpDialog },
    data: () => ({
        fileName: null,
        file: null,
        error: null,
        errors: [],
        warning: null,
        warnings: null,
        loading: false,
        successMessage: false,
    }),
    methods: {
        fileChange(file) {
            if (file) {
                this.fileName = file.name;
            }
        },
        upload() {
            this.clearError();
            this.clearMessage();
            this.clearWarning();
            if (this.file === null) {
                this.error = "No file is provided";
                return;
            }
            let formData = new FormData();
            formData.append("file", this.file);
            this.loading = true;
            validateFile(formData)
                .then((response) => {
                    const data = response?.data;

                    if (data?.status == "VALID") {
                        this.successMessage = true;
                    } else {
                        this.errors = data?.errors || [];
                        this.error = "Validation errors detected.";
                    }

                    if (data?.warnings?.length > 0) {
                        this.warnings = data?.warnings || [];
                        this.warning = "Validation warnings detected.";
                    }
                })
                .catch((error) => {
                    this.errors = error?.response?.data?.errorList || [];
                    this.error = parseError(error);
                })
                .finally(() => {
                    this.loading = false;
                    this.clearFileInput();
                });
        },
        clearFileInput() {
            this.$refs.fileInputRef.clearableCallback();
        },
        clearMessage() {
            this.successMessage = false;
            this.loading = false;
        },
        clearError() {
            this.errors = [];
            this.error = null;
        },
        clearWarning() {
            this.warnings = null;
            this.warning = null;
        },
        convertToExcelAddress(sheetName, columnNumber, rowNumber) {
            return `${this.$t("sheet")} '${sheetName}', ${this.$t("cell")} ${this.columnToLetter(columnNumber)}${rowNumber}`;
        },
        columnToLetter(column) {
            let temp,
                letter = "";
            while (column > 0) {
                temp = (column - 1) % 26;
                letter = String.fromCharCode(temp + 65) + letter;
                column = (column - temp - 1) / 26;
            }
            return letter;
        },
    },
    computed: {
        showErrorAlert() {
            return this.error || this.errors.length > 0;
        },
    },
};
</script>
