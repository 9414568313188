import 'material-design-icons-iconfont/dist/material-design-icons.css' // Ensure you are using css-loader
import Vue from 'vue';
import Vuetify from 'vuetify';
import Configuration from "@/services/configuration";
import 'vuetify/dist/vuetify.min.css';


Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        iconfont: 'md',
    },
    theme: {
        themes: {
            light: {
                // From teambaromerter
                primary: '#2196F3',
                secondary: '#757575',
                accent: '#2196F3',
                error: '#F44336',
                info: '#FF9800',
                success: '#8BC34A',
                background: Configuration.value('appBarColor') || '#2196F3'
              }
        },
    },
});
