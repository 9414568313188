import Vue from 'vue'
import VueRouter from 'vue-router'
import Validator from "../views/Validator";
import Gallery from "../views/Gallery";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'home',
        redirect: '/validate',
    },
    {
        path: '/validate',
        name: 'validate',
        component: Validator
    },
    {
        path: '/gallery',
        name: 'gallery',
        component: Gallery
    }
]

const router = new VueRouter({
    routes
})

export default router
