<template>
    <nav>
        <v-app-bar flat app dense color="rgb(248, 248, 248)">
            <v-tabs>
                <v-tab

                    to="/validate"
                    :class="{ 'black--text': $route.name !== 'validate' }"
                    :ripple="false"
                    >
                    <span v-if="$vuetify.breakpoint.smAndUp">{{ $t('menuValidate') }}</span>
                    <v-icon v-else>mdi-table</v-icon>

                    </v-tab
                >
                <v-tab

                    to="/gallery"
                    :class="{ 'black--text': $route.name !== 'gallery' }"
                    :ripple="false"
                    >

                    <span v-if="$vuetify.breakpoint.smAndUp">   {{ $t('menuGallery') }}</span>
                    <v-icon v-else>mdi-image</v-icon>

                    </v-tab
                >
            </v-tabs>

            <v-spacer></v-spacer>

            <v-select
                hide-details
                background-color="transparent"
                solo
                flat
                :menu-props="{ bottom: true, offsetY: true }"
                v-model="$i18n.locale"
                :items="['de', 'fr', 'it', 'en']"
                @input="setLang"
                style="max-width: 80px"
                ><template v-slot:selection="{ item }">
                    {{ item.toUpperCase() }}
                </template>
                <template v-slot:item="{ item }">
                    {{ item.toUpperCase() }}
                </template>
            </v-select>
        </v-app-bar>
    </nav>
</template>

<script>
export default {
    props: [""],
    mounted() {
        this.$i18n.locale = navigator.language.substring(0, 2); // setting default loale to user browser language
        this.setLang();
    },
    components: {},
    data: () => ({}),
    methods: {
        goTo(path) {
            this.$router.push({ name: path });
        },
        setLang() {
            document.title = this.$t("validatorTitle"); //browser tab title
            document.documentElement.lang = this.$i18n.locale; //html meta tag for google translate
        }
    },
    computed: {}
};
</script>
